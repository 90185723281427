import request from '@/utils/request'

export default {
  // 获取学历接口
  getEducation(){
    return request({
      url: '/coursepaper/getEducation',
      method: 'get'
    })
  },
  // 获取字数
  getWordsNum(){
    return request({
      url: '/coursepaper/getWordsNum',
      method: 'get'
    })
  }
}
